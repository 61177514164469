jQuery(document).ready(function($) {
	// Primary nav
	const $menuBtn = $(".global-header--nav-btn");
	const $menuContainer = $(".global-header--nav__mobile");
	$menuBtn.click(function() {
		$(this).toggleClass("is-active");
		$("body").toggleClass("menu-active");
		$menuContainer.toggleClass("is-active");
	});

	$(".projects-slider--images").slick({
		// settings: "unslick",
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 4000,
		arrows: true,
		infinite: true,
		dots: false,
		centerMode: true,
		centerPadding: "20%",
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 1,
					arrows: false,
					dots: true,
					centerMode: true,
					centerPadding: "0px",
				}
			}
		]
	});

	$(".team-list").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		arrows: true,
		dots: false,
		centerMode: false,
		centerPadding: "0",
		responsive: [
			{
				breakpoint: 9999,
				settings: "unslick"
			},
			{
				breakpoint: 800,
				settings: {
					// autoplay: false,
				}
			}
		]
	});

	$(".image").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		arrows: false,
		dots: true,
		centerMode: false,
		centerPadding: "0",
	});

	console.log("Testing");

	$(".fancybox").fancybox();

});

// Cookies policy

var dropCookie = true;                  // False disables Cookie for testing
var cookieDuration = 7;                // Number of days before cookie expires
var cookieName = 'ssAcceptedCookies';
var cookieValue = 'on';
 
function createDiv(){
    var bodytag = document.getElementsByTagName('body')[0];
    var div = document.createElement('div');
    div.setAttribute('id','cookies-banner');
    // Change href below if your cookie policy page link is not /cookies/
    div.innerHTML = '<p>We use cookies to give you the best experience on our website. By continuing, you agree to our <a href="/cookies-policy/" rel="nofollow" title="Cookie Policy">cookies policy</a>. <span><a class="close-cookie-banner" href="javascript:void(0);" onclick="removeMe();">I agree</a></span>.</p>';    
    bodytag.insertBefore(div,bodytag.firstChild); // Add the banner just after the opening <body> tag
    createCookie(window.cookieName,window.cookieValue, window.cookieDuration); // Create the cookie
}  
function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000)); 
        var expires = "; expires="+date.toGMTString(); 
    }
    else var expires = "";
    if(window.dropCookie) { 
        document.cookie = name+"="+value+expires+"; path=/"; 
    }
} 
function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
} 
function eraseCookie(name) {
    createCookie(name,"",-1);
} 
window.onload = function(){
    if(checkCookie(window.cookieName) != window.cookieValue){
        createDiv(); 
    }
}
function removeMe(){
    var element = document.getElementById('cookies-banner');
    element.parentNode.removeChild(element);
}